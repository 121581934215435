var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "xgplayer-live-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.videoLive,
              expression: "videoLive",
            },
          ],
          staticClass: "videoBox",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.videoLive && _vm.isShowMenu,
                  expression: "videoLive && isShowMenu",
                },
              ],
              staticClass: "video-menu",
            },
            [
              _vm.screen
                ? _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "8px" },
                      on: { click: _vm.getScreenShot },
                    },
                    [
                      !_vm.screening
                        ? _c("img", {
                            attrs: { src: "/img/map/texture-white.png" },
                          })
                        : _c("i", { staticClass: "el-icon-loading" }),
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("screenShot")) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.showDownLoad && _vm.getVideoUrl
                ? _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "8px" },
                      on: { click: _vm.downLoadVideo },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-download",
                        staticStyle: { "font-size": "20px" },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("downLoadVideo")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "xgvideo", attrs: { id: "xg-common" } }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.videoLive,
              expression: "!videoLive",
            },
          ],
          on: { click: _vm.showLive },
        },
        [_vm._t("close", [_c("div", { staticClass: "videoBtn" })])],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: _vm.$t("screenShot"),
            visible: _vm.screenDialogVisible,
            width: "30%",
            center: "",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "before-close": _vm.resetForm,
          },
          on: {
            "update:visible": function ($event) {
              _vm.screenDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("live.warningType"),
                    prop: "alarmType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: _vm.$t("live.chooseWarningType") },
                      model: {
                        value: _vm.form.alarmType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "alarmType", $$v)
                        },
                        expression: "form.alarmType",
                      },
                    },
                    _vm._l(_vm.alarmTypeArr, function (item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictValue, value: item.dictKey },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlerCancel } }, [
                _vm._v(_vm._s(_vm.$t("cancelText"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerOkScreen },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }